@mixin w {
  @media only screen and (max-width: "1440px") {
    @content; } }
@mixin d {
  @media only screen and (max-width: "1260px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "992px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "767px") {
    @content; } }

@mixin f {
  font-family: 'Montserrat', sans-serif; }

$green: #39d420;
$red: #fd001e;
$violet: #5c2d91;
$blue: #0058a4;

$cl: #181717;

$bg: #f7f7f7;

body {
  min-width: 320px;
  background: $bg;
  @include f;
  font-size: 16px;
  color: $cl;
  line-height: 1.625;
  &.no-scroll {
    overflow: hidden; } }
button,
input,
textarea,
select {
  @include f;
  font-size: 16px; }
a {
  text-decoration: none; }

.wow {
  visibility: hidden; }

.out {
  overflow: hidden; }

.center {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 120px;
  @include w {
    padding: 0 105px; }
  @include d {
    padding: 0 45px; }
  @include t {
    padding: 0 112px; }
  @include m {
    padding: 0 15px; } }

.stats {
  position: fixed;
  top: calc(50% - 38px);
  right: 22px;
  z-index: 99;
  @include w {
    right: 12px; }
  @include s {
    right: 0;
    transform: scale(.7); }
  &__btn {
    position: relative;
    width: 76px;
    height: 76px;
    border-radius: 50%;
    background: url(../img/bg-button.jpg) no-repeat 50% 50% / 102% 102%;
    font-size: 0;
    transition: opacity .2s;
    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      bottom: -10px;
      right: -10px;
      border-radius: 50%;
      border: 1px solid rgba(white,.04);
      transition: border-color .2s; }
    &:after {
      content: '';
      display: inline-block;
      width: 37px;
      height: 37px;
      background: url(../img/openblock-sm.png) no-repeat 50% 50% / contain; }
    &:hover {
      opacity: .7; }
    &.active {
      &:before {
        border-color: rgba(white,.4); } } }
  &__drop {
    display: none;
    position: absolute;
    top: 50%;
    right: 98px;
    width: 260px;
    transform: translateY(-50%);
    &.visible {
      display: block; } }
  &__drop.visible &__item {
    opacity: 1; }
  &__item {
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 65px rgba(68, 68, 173, 0.09);
    opacity: 0;
    transition: opacity .2s;
    @include nl {
      margin-bottom: 10px; }
    &:nth-child(2) {
      transition-delay: .1s; }
    &:nth-child(3) {
      transition-delay: .2s; } }
  &__head {
    height: 47px;
    background-image: linear-gradient(to right, #4a42ac 0%, #2a4bb7 100%);
    border-radius: 7px 7px 0px 0px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: white;
    line-height: 47px; }
  &__body {
    padding: 11px 0;
    line-height: 1.2; }
  &__row {
    display: flex;
    align-items: center; }
  &__col {
    flex: 0 0 50%;
    &:first-child {
      text-align: center; } }
  &__time {
    font-size: 18px;
    font-weight: 700; }
  &__date {
    font-size: 14px;
    font-weight: 600;
    color: #6f6f6f; }
  &__value {
    font-size: 18px;
    font-weight: 700; }
  &__percent {
    font-size: 24px;
    color: $green;
    &.red {
      color: $red; } }
  &__foot {
    display: block;
    padding: 9px 10px 8px;
    border-top: 1px solid #f0f0f0;
    font-weight: 600;
    color: $cl;
    text-align: center; } }

.socials {
  position: fixed;
  right: 32px;
  bottom: 32px;
  padding: 10px;
  background: rgba(247, 247, 247, 0.4);
  border: 1px solid rgba(0, 91, 170, 0.11);
  border-radius: 28px;
  z-index: 89;
  @include w {
    right: 18px;
    bottom: 18px; }
  @include s {
    right: 10px;
    bottom: 10px;
    transform-origin: 100% 100%;
    transform: scale(.8); }
  &__item {
    display: flex;
    width: 36px;
    height: 36px;
    background-image: linear-gradient(125deg, #005baa 0%, $violet 100%);
    border-radius: 50%;
    transition: opacity .2s;
    @include nl {
      margin-bottom: 10px; }
    &:hover {
      opacity: .7; }
    .icon {
      font-size: 14px;
      margin: auto;
      &-telegram,
      &-twitter {
        font-size: 15px; }
      &-instagram,
      &-facebook {
        font-size: 18px; } } } }

.btn {
  display: inline-block;
  min-width: 275px;
  padding: 17px 30px 16px;
  background: $violet;
  border-radius: 5px;
  box-shadow: 6px 8px 49px rgba(68, 68, 173, 0.06);
  font-size: 18px;
  font-weight: 600;
  color: white;
  text-align: center;
  text-transform: uppercase;
  transition: opacity .2s;
  &:hover {
    opacity: .9; } }

.action {
  display: inline-block;
  min-width: 215px;
  margin-right: 10px;
  padding: 17px 20px;
  background: white;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  color: $violet;
  transition: opacity .2s;
  &:hover {
    opacity: .7; }
  &_stroke {
    background: transparent;
    color: white; } }

.lang {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: white;
  &__head {
    padding: 6px;
    white-space: nowrap;
    cursor: pointer;
    &:after {
      content: '';
      display: inline-block;
      position: relative;
      top: -1px;
      width: 8px;
      height: 6px;
      margin-left: 6px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCA4IDYiPiAgPHBvbHlnb24gZmlsbD0iI0ZGRiIgcG9pbnRzPSI0LjI1IDMuNTQzIDggLjE5MSA4IDEuNjI1IDQuMjUgNS4wODUgLjUgMS42MiAuNSAuMTkxIi8+PC9zdmc+);
      background-repeat: no-repeat;
      background-size: contain;
      vertical-align: middle; } }
  &__drop {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    border-radius: 6px; }
  &__item {
    display: block;
    padding: 6px;
    color: $cl; }
  &:hover &__drop {
    display: block; } }

.title {
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.1; }

.logo {
  display: block;
  width: 188px;
  height: 37px;
  background: url(../img/openblock.png) no-repeat 50% 50% / contain; }

.main {
  position: relative;
  background: url(../img/bg-main.jpg) no-repeat 50% 50% / cover;
  color: white;
  z-index: 20;
  @include m {
    position: static;
    padding-top: 108px; }
  &__header {
    display: flex;
    margin-bottom: 90px;
    padding: 9px 0;
    align-items: center;
    @include t {
      margin-bottom: 70px; }
    @include m {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      padding: 9px 15px;
      background: #6b32a8;
      z-index: 109; } }
  &__logo {
    margin-right: 60px;
    @include m {
      display: none; } }
  &__toggle {
    display: none;
    position: relative;
    background: none;
    z-index: 30;
    @include w {
      display: block; } }
  &__toggle.active {
    @include s {
      position: fixed;
      top: 14px;
      left: 15px; } }
  &__toggle.active &__burger {
    &:before {
      transform: translateY(10px) rotate(45deg); }
    span {
      transform: rotate(-45deg); }
    &:after {
      transform: translateY(-10px) rotate(45deg); } }
  &__toggle.active &__label {
    display: none;
    &:last-child {
      display: block; } }
  &__burger,
  &__labels {
    display: inline-block;
    vertical-align: middle; }
  &__burger {
    position: relative;
    width: 30px;
    height: 30px;
    margin-right: 22px;
    padding-top: 4px;
    @include s {
      margin-right: 12px; }
    &:before,
    &:after,
    span {
      display: block;
      width: 30px;
      height: 1px;
      background: white;
      transition: transform .2s, width .2s; }
    &:before,
    &:after {
      content: ''; }
    span {
      margin: 9px 0; } }
  &__labels {
    font-size: 14px;
    font-weight: 500;
    color: white;
    text-transform: uppercase; }
  &__label {
    &:last-child {
      display: none; } }
  &__wrap {
    @include w {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 90px 0;
      background: rgba($violet,.98);
      opacity: 0;
      visibility: hidden;
      transition: opacity .2s, visibility .2s;
      z-index: 20;
      &.visible {
        opacity: 1;
        visibility: visible; } }
    @include m {
      position: fixed;
      height: 100%; } }
  &__nav {
    display: flex;
    @include w {
      flex-direction: column;
      align-items: center; } }
  &__link {
    margin-right: 17px;
    padding: 10px 6px 8px;
    border-bottom: 2px solid transparent;
    font-size: 14px;
    font-weight: 500;
    color: white;
    transition: border-color .2s;
    @include w {
      margin: 0; }
    &:hover {
      border-color: white; } }
  &__socials {
    display: none;
    @include w {
      display: flex;
      margin-top: 40px;
      justify-content: center; } }
  &__social {
    display: flex;
    height: 45px;
    margin: 0 30px;
    background: white;
    border-radius: 50%;
    flex: 0 0 45px;
    .icon {
      margin: auto;
      fill: $violet; } }
  &__lang {
    margin-left: auto;
    @include s {
      display: none; } }
  &__btn {
    min-height: 40px;
    min-width: 179px;
    margin-left: 20px;
    padding: 10px;
    background: white;
    border-radius: 20px;
    font-size: 14px;
    text-transform: uppercase;
    transition: opacity .2s;
    &:hover {
      opacity: .7; }
    @include s {
      min-width: 166px;
      margin-left: auto; } }
  &__container {
    position: relative;
    z-index: 2; }
  &__preview {
    position: absolute;
    top: 160px;
    left: 640px;
    width: 45%;
    z-index: -2;
    @include d {
      width: 35%; }
    @include t {
      display: none; } }
  &__pic {
    display: block;
    @include nf {
      position: absolute; }
    &:nth-child(2) {
      left: 6.2%;
      bottom: 57%;
      width: 9%; }
    &:nth-child(3) {
      left: 23.8%;
      bottom: 96%;
      width: 8%; }
    &:nth-child(4) {
      left: 85.4%;
      bottom: 69%;
      width: 9%; }
    &:nth-child(5) {
      bottom: 49%;
      left: -1.1%;
      width: 102.1%;
      opacity: .9; } }
  &__content {
    max-width: 670px;
    @include t {
      margin: 0 auto;
      text-align: center; } }
  &__title {
    margin-bottom: 15px;
    @include t {
      margin-bottom: 25px; }
    @include m {
      max-width: 500px;
      margin: 0 auto 20px; } }
  &__actions {
    display: flex;
    margin: 48px 0 26px;
    @include t {
      margin: 35px 0 20px;
      justify-content: center; }
    @include m {
      margin: 20px 0 30px; }
    @include s {
      display: block;
      margin: 30px 0 10px; } }
  &__action {
    @include nl {
      margin-right: 10px;
      @include s {
        margin-right: 0; } }
    @include s {
      width: 100%;
      margin-bottom: 20px; } }
  &__row {
    display: flex;
    margin-bottom: 20px;
    @include t {
      display: block;
      margin-bottom: 15px; } }
  &__col {
    &:first-child {
      width: 596px;
      @include t {
        margin: 0 auto 20px; }
      @include m {
        width: 100%; } }
    &:last-child {
      display: flex;
      width: calc(100% - 596px);
      @include t {
        width: 100%;
        justify-content: center; } } }
  &__group {
    padding: 25px 28px 22px;
    background: rgba(#6544c2,.7);
    border-radius: 5px;
    @include s {
      padding: 15px 10px; } }
  &__vlink {
    margin: auto 66px 7px auto;
    @include d {
      margin-right: 0; }
    @include t {
      margin: 0; } }
  &__foot {
    height: 45px; }
  &.animation &__pic {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      animation: main 2s linear infinite; }
    &:nth-child(3) {
      animation-delay: .5s; }
    &:nth-child(4) {
      animation-delay: 1s; } } }

@keyframes main {
  0, 100% {
    transform: translateY(0); }
  50% {
    transform: translateY(-40%); } }

.vlink {
  display: flex;
  width: 260px;
  padding: 5px;
  color: white;
  justify-content: flex-end;
  align-items: center;
  @include t {
    flex-direction: column;
    justify-content: center; }
  &__wrap {
    padding-right: 12px;
    text-align: right;
    @include t {
      padding: 0 0 5px;
      text-align: center; } }
  &__title {
    font-size: 18px;
    line-height: 1.3; }
  &__time {
    font-size: 14px;
    opacity: .4; }
  &__preview {
    position: relative;
    width: 60px;
    height: 60px;
    background: white;
    border-radius: 50%;
    &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      border: 1px solid rgba(white,.1);
      border-radius: 50%;
      box-shadow: 6px 8px 49px rgba(68, 68, 173, 0.06); } }
  &__pic {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: .5;
    object-fit: cover; } }

.timer {
  display: flex;
  margin-bottom: 18px;
  align-items: center;
  @include s {
    display: block; }
  &__title {
    padding: 0 30px 27px 0;
    line-height: (18/16);
    flex: 0 0 124px;
    @include m {
      padding-right: 20px;
      flex: 0 0 100px; }
    @include s {
      margin-bottom: 15px;
      padding: 0;
      text-align: center; } }
  &__wrap {
    position: relative;
    text-align: center;
    flex: 0 0 calc(100% - 124px);
    @include m {
      flex: 0 0 calc(100% - 100px); }
    @include s {
      width: 200px;
      margin: 0 auto; } }
  &__row {
    display: flex;
    margin: 0 -6px;
    flex-wrap: wrap;
    &:first-child {
      min-height: 49px; }
    &:last-child {
      margin-top: 6px;
      font-size: 14px;
      color: #C5B9E7;
      opacity: .6;
      @include s {
        position: absolute;
        top: 46px;
        left: 0;
        right: 0;
        height: auto; } } }
  &__row:last-child &__col {
    @include s {
      height: 49px; } }
  &__col {
    display: flex;
    padding: 0 6px;
    justify-content: center;
    flex: 0 0 25%;
    @include s {
      margin-bottom: 32px;
      flex: 0 0 50%; } }
  &__val {
    height: 49px;
    background: #734ddb;
    border-radius: 3px;
    font-size: 30px;
    font-weight: 700;
    line-height: 49px;
    flex: 0 0 calc(50% - 2px);
    &:first-child {
      margin-right: 4px;
      font-size: 0;
      &:first-letter {
        font-size: 30px; } }
    &:last-child {
      &:first-letter {
        font-size: 0; } } } }

.progress {
  height: 44px;
  background: #734ddb;
  border-radius: 3px;
  &__range {
    display: flex;
    height: 44px;
    padding-left: 10px;
    background-image: linear-gradient(to right, #734ddb 0%, white 99%, white 100%);
    border-radius: 3px 0 0 3px;
    box-shadow: 0 0 24px rgba(196, 178, 219, 0.22);
    color: $cl;
    text-align: right;
    line-height: 1;
    justify-content: flex-end;
    align-items: center; }
  &__logo {
    display: flex;
    height: 44px;
    margin: 0 -22px 0 11px;
    border-radius: 50%;
    background: #6642bb;
    box-shadow: 0 0 35px rgba(196, 178, 219, 0.22);
    flex: 0 0 44px;
    &:before {
      content: '';
      margin: auto;
      width: 28px;
      height: 28px;
      background: url(../img/openblock-sm.png) no-repeat 50% 50% / contain; } } }

.logos {
  display: flex;
  position: relative;
  padding: 29px 42px;
  background: #46226f;
  border-radius: 5px;
  box-shadow: -0.5px -10px 38px rgba(81, 63, 172, 0.19);
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
  @include t {
    padding: 29px 9px;
    text-align: center;
    justify-content: center; }
  &__item {
    font-size: 0;
    @include t {
      flex: 0 0 33.33%; }
    @include s {
      margin-bottom: 20px;
      padding: 0 10px;
      flex: 0 0 50%; }
    &:nth-child(4),
    &:nth-child(5) {
      @include t {
        margin-top: 35px; } }
    &:nth-child(4) {
      @include s {
        margin: 0 0 20px; } }
    &:nth-child(5) {
      @include s {
        margin: 0; } } }
  &__pic {
    max-width: 100%; } }

.video {
  display: block;
  position: relative;
  border-radius: 8px;
  color: white;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(126deg, rgba(0, 91, 170, 0.37) 0%, rgba(92, 45, 145, 0.37) 100%);
    border-radius: 8px; }
  &:hover &__play {
    opacity: .7; }
  &:hover &__iframe {
    opacity: 1; }
  &__pic {
    display: block;
    width: 100%;
    border-radius: 8px; }
  &__play,
  &__date,
  &__time,
  &__counter {
    position: absolute;
    z-index: 2; }
  &__play {
    top: 50%;
    left: 50%;
    width: 82px;
    height: 82px;
    background: rgba(white,.8);
    box-shadow: 6px 8px 49px rgba(68, 68, 173, 0.06);
    border-radius: 50%;
    transform: translate(-50%,-50%);
    transition: opacity .2s;
    &:before {
      content: '';
      position: absolute;
      top: -7px;
      left: -7px;
      right: -7px;
      bottom: -7px;
      border-radius: 50%;
      border: 1px solid rgba(white,.1);
      box-shadow: 6px 8px 49px rgba(68, 68, 173, 0.06); }
    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 6px);
      @include arr(20,24,$violet,r); } }
  &__date {
    top: 0;
    left: 0;
    width: 100%;
    padding: 25px; }
  &__time {
    right: 0;
    bottom: 0;
    padding: 25px;
    font-size: 14px;
    color: rgba(white,.5); }
  &__counter {
    left: 0;
    bottom: calc(100% + 20px);
    width: 20px;
    transform: rotate(-90deg); }
  &__iframe,
  &__btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // opacity: 0
    z-index: 10; }
  &__btn {
    opacity: .2;
    background: red;
    z-index: 99; }
  &_how &__play {
    width: 68px;
    height: 68px; } }

.whatis {
  padding: 204px 0 162px;
  @include t {
    padding-top: 235px; }
  &__row {
    display: flex;
    margin: 0 -25px;
    align-items: center;
    @include d {
      margin: 0 -15px; }
    @include t {
      text-align: center;
      align-items: stretch;
      flex-direction: column; } }
  &__col {
    padding: 0 25px;
    flex: 0 0 50%;
    @include d {
      padding: 0 15px; }
    @include t {
      flex: 0 0 100%; }
    &:first-child {
      @include t {
        order: 2; } }
    &:last-child {
      padding-bottom: 40px;
      @include w {
        padding-bottom: 0; }
      @include t {
        margin-bottom: 20px;
        order: 1; } } }
  &__text {
    @include t {
      max-width: 660px;
      margin: 0 auto;
      font-size: 18px; } }
  &__video {
    @include t {
      max-width: 500px;
      margin: 0 auto; } } }

.content {
  ul {
    counter-reset: decimals;
    font-weight: 600;
    line-height: (26/16); }
  li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 32px;
    &:before {
      counter-increment: decimals;
      content: counter(decimals);
      position: absolute;
      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      background: white;
      border-radius: 50%;
      font-size: 14px;
      color: $red;
      text-align: center;
      line-height: 22px; }
    p {
      margin: 10px 0 0 -30px;
      font-weight: 400; } }
  &_green {
    li {
      &:before {
        color: $green; } } } }

.benefits {
  position: relative;
  padding: 50px 0 40px;
  background: url('../img/bg-benefits.jpg') no-repeat 50% 50% / cover;
  color: white;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 70%;
    border: 1px solid rgba(white,.1);
    @include t {
      height: 100%; }
    @include m {
      display: none; } }
  &__container {
    position: relative; }
  &__row {
    display: flex;
    @include t {
      margin: 0 -30px; }
    @include m {
      margin: 0 -10px; }
    @include s {
      display: block; } }
  &__col {
    flex: 0 0 350px;
    @include t {
      padding: 0 30px;
      flex: 0 0 50%; }
    @include m {
      padding: 0 10px; }
    &:last-child {
      margin-left: auto;
      flex: 0 0 375px;
      @include t {
        flex: 0 0 50%; }
      @include s {
        margin-top: 45px; } } }
  &__title {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 700;
    line-height: (42/30);
    text-transform: uppercase;
    @include m {
      margin-bottom: 30px;
      font-size: 24px; } }
  &__preview {
    position: absolute;
    top: 50%;
    left: 325px;
    right: 350px;
    transform: translateY(-45%);
    @include t {
      display: none; } }
  &__pic {
    display: block;
    width: 100%;
    @include nf {
      position: absolute;
      top: 0;
      left: 0; }
    &:nth-child(2) {
      top: 10%;
      left: 18%;
      width: 59%;
      opacity: 0; }
    &:nth-child(3) {
      width: 25%;
      top: 32%;
      left: 24%; }
    &:nth-child(4) {
      width: 21.7%;
      top: 33%;
      left: 10%; } }
  &.animation &__pic {
    &:nth-child(2) {
      animation: benefits-light 7s linear infinite; }
    &:nth-child(3) {
      g > * {
        &:nth-child(4n+1) {
          animation: benefits 1s linear infinite; }
        &:nth-child(4n-1) {
          animation: benefits 1s .5s linear infinite; } } } } }

@keyframes benefits {
  0, 100% {
    transform: translateY(0); }
  50% {
    transform: translateY(10%); } }

@keyframes benefits-light {
  0%, 4%, 8%, 100% {
    opacity: 0; }
  2%, 6%, 10%, 98% {
    opacity: 1; } }

.features {
  padding: 110px 0;
  @include w {
    padding: 60px 0; }
  &__container {
    position: relative;
    overflow: visible;
    z-index: 4; }
  &__container.animated &__path {
    path {
      animation: features-dash 2.5s linear forwards; }
    circle {
      display: block; } }
  &__container.animated &__col {
    opacity: 1;
    &:nth-child(1) {
      transition-delay: .3s; }
    &:nth-child(2) {
      transition-delay: .55s; }
    &:nth-child(3) {
      transition-delay: .8s; }
    &:nth-child(4) {
      transition-delay: 1.05s; }
    &:nth-child(5) {
      transition-delay: 2.6s; }
    &:nth-child(6) {
      transition-delay: 2.3s; }
    &:nth-child(7) {
      transition-delay: 2s; } }
  &__path {
    position: absolute;
    top: 49%;
    left: 53%;
    width: 132%;
    transform: translate(-50%,-50%);
    z-index: -2;
    @include t {
      display: none; }
    &:before {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      width: 24px;
      height: 24px;
      background: $bg;
      pointer-events: none; }
    svg {
      display: block;
      width: 100%; }
    path {
      stroke-dasharray: 3094;
      stroke-dashoffset: 3094; }
    circle {
      display: none; } }
  &__row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include s {
      flex-direction: column; } }
  &__col {
    margin-bottom: 70px;
    opacity: 0;
    transition: opacity .2s;
    flex: 0 0 25%;
    @include t {
      margin-bottom: 20px;
      flex: 0 0 33.33%;
      &:nth-child(1),
      &:nth-child(3) {
        margin-left: 16.66%; }
      &:nth-child(2),
      &:nth-child(4) {
        margin-right: 16.66%; } }
    @include m {
      flex: 0 0 50%;
      &:nth-child(1),
      &:nth-child(3) {
        margin-left: 0; }
      &:nth-child(2),
      &:nth-child(4) {
        margin-right: 0; } }
    @include s {
      @include nl {
        margin-bottom: 20px; } } }
  &__col:nth-child(1) &__pic {
    &:nth-child(2) {
      left: 33.5%;
      bottom: 19.5%;
      width: 37%; }
    &:nth-child(3) {
      left: 28%;
      bottom: 15%;
      width: 10%;
      z-index: 2; } }
  &__col:nth-child(2) &__pic {
    &:nth-child(2) {
      left: 3%;
      bottom: 9%;
      width: 94%; }
    &:nth-child(3) {
      left: 13%;
      bottom: 19%;
      width: 74%; }
    &:nth-child(4) {
      left: 57%;
      bottom: 35%;
      width: 20%; } }
  &__col:nth-child(3) &__pic {
    &:nth-child(2) {
      left: 14%;
      bottom: 14%;
      width: 70%; }
    &:nth-child(3) {
      left: 41%;
      bottom: 42%;
      width: 30%; } }
  &__col:nth-child(4) &__pic {
    &:nth-child(2) {
      left: 74%;
      bottom: 12%;
      width: 12%; }
    &:nth-child(3) {
      left: 29%;
      bottom: 31%;
      width: 46%; } }
  &__col:nth-child(5) &__pic {
    &:nth-child(2) {
      left: 3%;
      bottom: 9%;
      width: 90%; }
    &:nth-child(3) {
      left: 31%;
      bottom: 23.5%;
      width: 38%;
      height: 20%; } }
  &__col:nth-child(6) &__pic {
    &:nth-child(2) {
      left: 21%;
      bottom: 33%;
      width: 60%; } }
  &__col:nth-child(7) &__pic {
    &:nth-child(2) {
      left: 24%;
      bottom: 31%;
      width: 53%; } }
  &__light {
    position: absolute;
    left: 5%;
    bottom: 16.2%;
    width: 91%; }
  &__preview {
    display: flex;
    position: relative;
    width: 172px;
    margin: 0 auto;
    height: 240px;
    margin-bottom: 25px; }
  &__pic {
    &:first-child {
      display: block;
      width: 100%;
      margin: auto auto 0; }
    @include nf {
      position: absolute; } }
  &__title {
    max-width: 200px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center; }
  &.animation &__col:nth-child(1) &__pic:nth-child(2),
  &.animation &__col:nth-child(1) &__light {
    animation: features-1 5s linear infinite; }
  &.animation &__col:nth-child(2) &__pic {
    &:nth-child(3) {
      animation: features-2 1s linear infinite; } }
  &.animation &__col:nth-child(4) &__pic {
    &:nth-child(3) {
      animation: features-vertical 1.5s linear infinite; } }
  &.animation &__col:nth-child(5) &__pic {
    &:nth-child(3) {
      animation: features-5 10s linear infinite; } }
  &.animation &__col:nth-child(6) &__pic {
    &:nth-child(2) {
      animation: features-vertical 2.5s linear infinite; } }
  &.animation &__col:nth-child(7) &__pic {
    &:nth-child(2) {
      animation: features-vertical 2s linear infinite; } } }

@keyframes features-dash {
  to {
    stroke-dashoffset: 0; } }

@keyframes features-vertical {
  0, 100% {
    transform: translateY(10%); }
  50% {
    transform: translateY(-10%); } }

@keyframes features-1 {
  0, 100% {
    opacity: 1; }
  50% {
    opacity: 0; } }

@keyframes features-2 {
  0, 100% {
    opacity: 1; }
  50% {
    opacity: .1; } }

@keyframes features-3 {
  0, 100% {
    transform: translateY(20%); }
  50% {
    transform: translateY(-20%); } }

@keyframes features-5 {
  0, 100% {
    height: 20%; }
  50% {
    height: 30%; } }

.how {
  padding: 65px 0 55px;
  background: url(../img/bg-how.jpg) no-repeat 100% 0 / auto 100%;
  background-color: #005AA7;
  color: white;
  @include t {
    padding: 55px 0 65px; }
  &__title {
    margin-bottom: 40px;
    @include t {
      margin-bottom: 50px; }
    @include m {
      margin-bottom: 20px; }
    br {
      @include t {
        display: none; } } }
  &__row {
    display: flex;
    margin: 0 -45px;
    @include d {
      margin: 0 -15px; }
    @include t {
      display: block;
      text-align: center; } }
  &__col {
    padding: 0 45px;
    flex: 0 0 50%;
    @include d {
      padding: 0 15px; } }
  &__content {
    @include t {
      max-width: 520px;
      margin: 0 auto;
      font-size: 18px; }
    li {
      margin-bottom: 25px;
      @include t {
        padding-top: 30px;
        &:before {
          top: 0;
          left: calc(50% - 11px); } } } }
  &__label {
    margin-bottom: 15px;
    padding-top: 20px;
    font-size: 18px;
    font-weight: 600;
    @include t {
      padding-top: 0; } }
  &__video {
    @include t {
      max-width: 500px;
      margin: 0 auto; } } }

.roadmap {
  padding: 95px 0;
  &__title {
    margin-bottom: 55px;
    text-align: center; }
  &__container {
    position: relative; }
  &__preview {
    position: relative;
    max-width: 230px;
    height: 262px;
    margin: 0 auto;
    z-index: 2; }
  &__pic {
    position: absolute;
    &:nth-child(1) {
      top: 39%;
      left: 0;
      width: 100%; }
    &:nth-child(2) {
      top: 4.6%;
      left: 17.2%;
      width: 68.4%;
      opacity: .2; }
    &:nth-child(3) {
      top: 0;
      left: 15%;
      width: 73%; } }
  &__line {
    position: absolute;
    top: 200px;
    left: calc(50% - 1px);
    width: 2px;
    height: 0;
    background: linear-gradient(#e5ddf2,$blue);
    transition: height .1s;
    @include s {
      display: none; } }
  &__list {
    position: relative;
    max-width: 970px;
    margin: 0 auto 70px;
    @include s {
      margin-bottom: 30px; } }
  &__item {
    display: flex;
    position: relative;
    margin: 0 -35px 40px;
    @include m {
      margin: 0 -20px 40px; }
    @include s {
      margin-bottom: 20px; } }
  &__item:nth-child(2n) &__col {
    &:first-child {
      order: 2; }
    &:nth-child(2) {
      text-align: right;
      order: 1; } }
  &__item:nth-child(2n) &__content {
    background-image: linear-gradient(to right, white 0%, $bg 100%);
    box-shadow: -31.9px 2.2px 49px rgba(68, 68, 173, 0.06);
    text-align: left;
    align-items: flex-start; }
  &__point {
    position: absolute;
    top: 35px;
    left: calc(50% - 7px);
    width: 14px;
    height: 14px;
    background: $blue;
    border-radius: 50%;
    box-shadow: 0 0 17.2px 3.8px rgba(0, 88, 164, 0.19);
    @include s {
      display: none; } }
  &__col {
    padding: 0 35px;
    flex: 0 0 50%;
    @include m {
      padding: 0 20px; }
    @include s {
      &:first-child {
        flex: 0 0 77%; }
      &:nth-child(2) {
        padding: 0;
        flex: 0 0 23%; } } }
  &__content {
    display: flex;
    padding: 27px 23px;
    background-image: linear-gradient(to right, $bg 0%, white 100%);
    border-radius: 8px;
    box-shadow: 32px 0 49px rgba(68, 68, 173, 0.06);
    text-align: right;
    align-items: flex-end;
    flex-direction: column;
    @include m {
      padding: 20px 15px; } }
  &__subtitle,
  &__text {
    max-width: 340px; }
  &__subtitle {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 600;
    @include t {
      margin-bottom: 5px; } }
  &__text {
    font-size: 16px;
    line-height: (26/16); }
  &__date {
    padding: 26px;
    @include s {
      padding: 0; } }
  &__year {
    font-size: 24px;
    font-weight: 700;
    color: $blue;
    line-height: 1.3; }
  &__quater {
    font-size: 12px;
    font-weight: 600;
    color: rgba($blue,.6);
    text-transform: uppercase; }
  &__final {
    display: flex;
    position: relative;
    width: 288px;
    height: 95px;
    margin: 0 auto;
    background: url(../img/bg-final.jpg) no-repeat 50% 50% / cover;
    border-radius: 8px;
    box-shadow: 0 10px 24px rgba(68, 68, 173, 0.2);
    font-size: 18px;
    font-weight: 600;
    color: white;
    align-items: center;
    justify-content: center;
    z-index: 2; }
  &.animation &__pic {
    &:nth-child(2) {
      animation: roadmap 1.5s linear infinite; } } }

@keyframes roadmap {
  0, 100% {
    opacity: .2; }
  50% {
    opacity: 1; } }

.videos {
  padding: 50px 0;
  background: url(../img/bg-videos.jpg) no-repeat 50% 50% / cover;
  color: white;
  &__title {
    margin-bottom: 130px;
    text-align: center; }
  &__current {
    margin-top: 35px;
    font-weight: 600;
    text-align: right; }
  .owl-carousel .owl-stage-outer {
    overflow: visible; }
  .owl-prev,
  .owl-next {
    display: flex;
    position: absolute;
    top: calc(50% - 29px);
    width: 58px;
    height: 58px;
    background: rgba(white,.7);
    border-radius: 50%;
    box-shadow: 6px 8px 49px rgba(68, 68, 173, 0.13);
    font-size: 0;
    transition: background .2s;
    &:before {
      content: '';
      width: 11px;
      height: 19px;
      margin: auto;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDEzIDIxIj4gIDxwb2x5Z29uIGZpbGw9IiM1QzJEOTEiIHBvaW50cz0iOS4zMzggMTAuMjUgLjA0OCAwIDIuOTkzIDAgMTIuMzk4IDEwLjI1IDIuOTkzIDIwLjUgLjA0OCAyMC41Ii8+PC9zdmc+);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%; }
    &:hover {
      background: white; }
    &.disabled {
      background: rgba(white,.2); } }
  .owl-prev {
    left: -88px;
    @include w {
      left: -83px; }
    &:before {
      transform: rotate(180deg); } }
  .owl-next {
    right: -88px;
    @include w {
      right: -83px; } } }

.obc {
  padding: 50px 0;
  @include t {
    padding: 100px 0 50px; }
  &__row {
    display: flex;
    margin: 0 -25px;
    align-items: center;
    @include t {
      text-align: center;
      align-items: stretch;
      flex-direction: column;
      justify-content: center; } }
  &__col {
    padding: 0 25px;
    flex: 0 0 50%; }
  &__text {
    margin-bottom: 30px;
    @include t {
      max-width: 640px;
      margin: 0 auto 20px;
      font-size: 18px; } }
  &__preview {
    position: relative;
    padding-top: 40px;
    @include t {
      margin-top: 20px; } }
  &__pic {
    display: block;
    max-width: 100%;
    @include nf {
      position: absolute;
      opacity: 0; }
    &:nth-child(2) {
      top: 21%;
      left: 40%;
      max-width: 22%; }
    &:nth-child(3) {
      top: 29%;
      left: 33.5%;
      max-width: 32%; }
    &:nth-child(4) {
      top: 25%;
      left: 34%;
      max-width: 31%; } }
  &.animation &__pic {
    @include nf {
      animation: obc .4s linear infinite; }
    &:nth-child(3) {
      animation-delay: .13s; }
    &:nth-child(4) {
      animation-delay: .26s; } } }

@keyframes obc {
  0, 100% {
    opacity: 0; }
  50% {
    opacity: 1; } }

.distrib {
  padding: 50px 0;
  &__title {
    margin-bottom: 50px;
    text-align: center; }
  &__row {
    display: flex;
    margin: 0 -23px;
    @include t {
      max-width: 560px;
      margin: 0 auto;
      flex-wrap: wrap; } }
  &__col {
    padding: 0 23px;
    font-size: 18px;
    text-align: center;
    flex: 0 0 16.66%;
    @include t {
      margin-bottom: 20px;
      flex: 0 0 33.33%; }
    @include s {
      max-width: 50%;
      flex: 0 0 50%; } }
  &__col:hover &__pic {
    opacity: 0;
    &:last-child {
      opacity: 1; } }
  &__preview {
    position: relative;
    margin-bottom: 10px;
    &:before {
      content: '';
      display: block;
      padding-bottom: 100%; } }
  &__pic {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    transition: opacity .2s;
    &:last-child {
      opacity: 0; } }
  &__type {
    margin-bottom: 5px; }
  &__value {
    font-weight: 600; } }

.funds {
  padding: 50px 0;
  &__title {
    margin-bottom: 50px;
    text-align: center;
    @include s {
      margin-bottom: 30px; } }
  &__row {
    display: flex;
    margin: 0 -10px;
    @include m {
      display: block; } }
  &__col {
    display: flex;
    padding: 0 10px;
    flex: 0 0 50%;
    @include m {
      display: block; }
    &:first-child {
      @include m {
        display: none; } } }
  &__chart {
    position: relative;
    margin-left: auto;
    flex: 0 0 303px;
    overflow: hidden; }
  &__chart.animated &__overlay {
    transform: rotate(-180deg); }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 280px;
    height: 560px;
    background: $bg;
    border-radius: 280px 0 0 280px;
    box-shadow: -2px 0 0 0 $bg, 0 -2px 0 0 $bg, 0 2px 0 0 $bg;
    transform-origin: 100% 50%;
    transition: transform 1.5s; }
  &__pic {
    display: block;
    max-width: 100%; }
  &__item {
    margin-bottom: 7px;
    padding: 1px 0;
    font-size: 22px;
    @include m {
      text-align: center; }
    @include s {
      margin-bottom: 2px;
      font-size: 18px; } } }

.people {
  padding: 40px 0;
  &__title {
    margin-bottom: 50px;
    text-align: center; }
  &__row {
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
    justify-content: center;
    @include t {
      display: block; } }
  &__col {
    position: relative;
    margin: 0 10px 20px;
    padding: 1px;
    flex: 0 0 calc(50% - 20px);
    @include m {
      margin-bottom: 30px; }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: linear-gradient(to right, rgba(black,.1), rgba(black,0)); } }
  &__item {
    display: flex;
    position: relative;
    min-height: 100%;
    padding: 14px 14px 24px;
    background: $bg;
    border-radius: 7px;
    @include s {
      display: block; } }
  &__preview {
    width: 146px;
    margin-right: 26px;
    flex: 0 0 146px;
    @include s {
      margin: 0 0 20px; } }
  &__pic {
    display: block;
    width: 100%;
    border-radius: 4px; }
  &__wrap {
    padding-top: 5px;
    flex: 0 0 calc(100% - 172px); }
  &__head {
    display: flex; }
  &__name {
    font-size: 20px;
    font-weight: 700;
    color: $blue; }
  &__link {
    display: flex;
    height: 20px;
    margin: 6px 0 0 10px;
    background: $violet;
    border-radius: 2px;
    flex: 0 0 20px;
    .icon {
      margin: auto;
      font-size: 15px; } }
  &__position {
    font-size: 16px;
    font-weight: 600;
    color: rgba($violet,.9); }
  &__text {
    margin-top: 10px;
    font-size: 14px;
    @include t {
      font-size: 16px; } } }

.partners {
  padding: 40px 0 130px;
  @include t {
    padding-bottom: 40px; }
  &__title {
    margin-bottom: 30px;
    text-align: center; }
  &__row {
    display: flex;
    margin: 0 -8px;
    flex-wrap: wrap;
    justify-content: center;
    @include m {
      display: block; } }
  &__col {
    position: relative;
    margin: 0 8px 16px;
    flex: 0 0 calc(25% - 16px);
    @include t {
      flex: 0 0 calc(50% - 16px); } }
  &__col:hover &__body {
    opacity: 1;
    visibility: visible; }
  &__head {
    position: relative;
    padding: 1px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: linear-gradient(to right, rgba(black,.1), rgba(black,0)); } }
  &__logo {
    display: flex;
    position: relative;
    height: 88px;
    background: $bg;
    border-radius: 7px; }
  &__pic {
    position: relative;
    margin: auto;
    z-index: 2; }
  &__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 88px 20px 20px;
    background-image: linear-gradient(to right, white 0%, $bg 100%);
    border-radius: 8px;
    box-shadow: -14px 0.7px 49px rgba(68, 68, 173, 0.08);
    font-size: 14px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
    @include t {
      position: relative;
      margin-top: -90px;
      font-size: 16px;
      opacity: 1;
      visibility: visible; } } }

.meet {
  padding: 50px 0;
  &__title {
    margin-bottom: 40px;
    @include t {
      text-align: center; } }
  &__container {
    position: relative;
    padding-right: calc(50% + 25px);
    @include d {
      padding-right: calc(50% + 15px); }
    @include t {
      padding: 0; } }
  &__item {
    margin-bottom: 15px;
    @include t {
      margin-bottom: 20px;
      text-align: center; } }
  &__link {
    display: block;
    position: relative;
    padding: 1px;
    color: $cl;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: linear-gradient(to right, rgba(black,.1), rgba(black,0));
      transition: opacity .2s; }
    &:hover {
      &:before {
        opacity: 0; } } }
  &__link:hover &__content {
    background: rgba(white,.5);
    box-shadow: 0 0 0 1px rgba(white,.5); }
  &__link.active &__content {
    background: white;
    box-shadow: 0 0 0 1px white; }
  &__content {
    position: relative;
    padding: 19px 28px;
    background: $bg;
    border-radius: 7px;
    transition: background .2s, box-shadow .2s; }
  &__date {
    font-size: 14px;
    text-transform: uppercase; }
  &__name {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.3; }
  &__location {
    margin-top: 8px;
    color: $blue; }
  &__preview,
  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(50% - 25px);
    height: 100%;
    overflow: hidden;
    @include d {
      width: calc(50% - 15px); } }
  &__preview {
    display: none;
    @include t {
      position: relative;
      width: 100%;
      max-width: 600px;
      height: 560px;
      margin: 20px auto 0; }
    @include s {
      height: 0;
      padding-bottom: 100%; }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(126deg, rgba(0, 91, 170, 0.37) 0%, rgba(92, 45, 145, 0.37) 100%);
      border-radius: 8px; }
    &.visible {
      display: block; } }
  &__overlay {
    border-radius: 8px;
    transition: opacity .2s;
    pointer-events: none;
    z-index: 4;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translate(-110%, -110%) rotate(45deg) scale(1.75);
      background-image: linear-gradient(126deg, rgba(0, 91, 170, 1) 0%, rgba(92, 45, 145, 1) 100%); }
    @include t {
      display: none; }
    &.visible {
      &:before {
        animation: meet .8s linear forwards; } } }
  &__pic {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    @include s {
      position: absolute;
      top: 0;
      left: 0; } }
  &__left {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 20px 40px;
    font-weight: 600;
    color: white;
    z-index: 2; } }

@keyframes meet {
  0 {
    transform: translate(-110%, -110%) rotate(45deg) scale(1.75); }
  100% {
    transform: translate(110%, 110%) rotate(45deg) scale(1.75); } }

.whitepaper {
  padding: 50px 0 140px;
  text-align: center;
  @include t {
    padding-top: 30px; }
  &__title {
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 600;
    @include s {
      line-height: 1.3; } }
  &__btn {
    min-width: 275px;
    text-transform: none; } }

.subscribe {
  max-width: 486px;
  margin-bottom: 35px;
  @include t {
    margin: 0 auto 35px; }
  &__title {
    margin-bottom: 12px;
    font-size: 22px;
    font-weight: 600; }
  &__form {
    display: flex;
    border-radius: 5px;
    box-shadow: 6px 8px 49px rgba(68, 68, 173, 0.03); }
  &__field {
    flex: 0 0 calc(100% - 170px); }
  &__input {
    width: 100%;
    height: 48px;
    padding: 0 22px;
    background: none;
    border: 1px solid #5a79b9;
    border-radius: 5px 0 0 5px;
    color: white;
    @include s {
      padding: 0 12px; }
    &::placeholder {
      color: #5a79b9; } }
  &__btn {
    width: 170px;
    height: 48px;
    margin-left: -1px;
    padding: 0 10px;
    background: white;
    border: 1px solid #acbedd;
    border-radius: 0px 5px 5px 0px;
    font-weight: 600;
    color: $violet;
    transition: opacity .2s;
    &:hover {
      opacity: .7; } } }

.networks {
  margin-bottom: 35px;
  @include t {
    justify-content: center; }
  @include s {
    flex-wrap: wrap; }
  &,
  &__item {
    display: flex;
    align-items: center; }
  &__item {
    color: white;
    transition: opacity .2s;
    @include s {
      margin-bottom: 10px;
      justify-content: center;
      flex: 0 0 50%; }
    @include nl {
      margin-right: 40px;
      @include s {
        margin: 0 0 10px; } }
    &:hover {
      opacity: .7; } }
  &__icon {
    margin-right: 15px;
    font-size: 0;
    .icon {
      font-size: 14px;
      margin: auto;
      &-telegram,
      &-twitter {
        font-size: 15px; }
      &-instagram,
      &-facebook {
        font-size: 18px; } } } }

.footer {
  position: relative;
  padding-top: 60px;
  background-image: linear-gradient(178deg, #005baa 0%, #5c2d91 100%);
  color: white;
  @include t {
    text-align: center; }
  &__center {
    position: relative; }
  &__row {
    display: flex;
    position: relative;
    @include t {
      display: block;
      padding-bottom: 100px; } }
  &__col {
    margin-right: 40px;
    flex: 0 0 260px;
    @include t {
      margin: 0; } }
  &__logo {
    margin-bottom: 26px;
    @include t {
      margin: 0 auto 26px; } }
  &__nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include t {
      align-items: center; }
    &_sm {
      @include t {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%; } } }
  &__nav_sm &__link {
    margin-bottom: 16px;
    font-size: 14px; }
  &__link {
    margin-bottom: 22px;
    color: white;
    transition: opacity .2s;
    &:hover {
      opacity: .7; } }
  &__down {
    display: flex;
    margin-top: 62px;
    padding: 22px 0 12px;
    border-top: 1px solid rgba(white,.05);
    font-size: 14px;
    align-items: flex-end;
    @include t {
      margin-top: 20px; }
    @include s {
      flex-direction: column;
      align-items: center; } }
  &__copyright {
    margin-right: auto;
    color: rgba(white,.8);
    @include s {
      margin: 0 0 20px; } }
  &__created {
    display: flex;
    color: rgba(white,.8);
    align-items: flex-end; }
  &__bg {
    position: absolute;
    top: -30px;
    left: 810px;
    width: 750px;
    pointer-events: none;
    @include d {
      left: 600px;
      width: 400px; }
    @include m {
      display: none; } }
  &__bg &__pic {
    @include nl {
      position: absolute;
      opacity: .3; }
    &:nth-child(1) {
      position: absolute;
      top: 5%;
      left: 5%;
      width: 10%; }
    &:nth-child(2) {
      top: 5%;
      left: 67%;
      width: 10%; }
    &:nth-child(3) {
      position: absolute;
      top: 62%;
      left: 73%;
      width: 17%; }
    &:nth-child(4) {
      position: absolute;
      top: 24%;
      left: 27%;
      width: 25.5%; } }
  &__pic {
    display: block;
    max-width: 100%; }
  &.animation &__bg &__pic {
    &:nth-child(1) {
      animation: light-one 2s linear infinite; }
    &:nth-child(2) {
      animation: light-two 2s linear infinite; }
    &:nth-child(3) {
      animation: light-three 2s linear infinite; }
    &:nth-child(4) {
      animation: light-four 6s 2s linear forwards; } } }

@keyframes light-one {
  0 {
    opacity: .2;
    transform: translate(0,0); }
  100% {
    opacity: .4;
    transform: translate(290%, 210%) scale(2); } }

@keyframes light-two {
  0 {
    opacity: .2;
    transform: translate(0,0); }
  100% {
    opacity: .4;
    transform: translate(-325%,220%) scale(2); } }

@keyframes light-three {
  0 {
    opacity: .2;
    transform: translate(0,0); }
  100% {
    opacity: .4;
    transform: translate(-250%,-130%) scale(1.5); } }

@keyframes light-four {
  0 {
    opacity: 0; }
  100% {
    opacity: 1; } }

.lightbox {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(black,.9);
  opacity: 0;
  transition: opacity .2s;
  z-index: 9999;
  &.visible {
    opacity: 1; }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 44px;
    padding: 11px;
    background: rgba(black,.5);
    font-size: 0;
    &:before,
    &:after {
      content: '';
      display: block;
      width: 22px;
      height: 2px;
      background: white;
      border-radius: 2px; }
    &:before {
      transform: translateY(1px) rotate(45deg); }
    &:after {
      transform: translateY(-1px) rotate(-45deg); } }
  &__wrap {
    display: flex;
    position: absolute;
    top: 44px;
    left: 44px;
    right: 44px;
    bottom: 44px;
    align-items: center;
    justify-content: center; }
  &__img {
    display: none;
    max-width: 100%;
    max-height: 100%; }
  &__iframe {
    display: none;
    width: 100%;
    height: 100%;
    border: none; } }




