.icon-chevron {
  width: 1.29em;
  height: 1em;
  fill: #FFF;
}
.icon-facebook {
  width: 0.56em;
  height: 1em;
  fill: #FFF;
}
.icon-instagram {
  width: 1em;
  height: 1em;
  fill: #FFF;
}
.icon-linkedin {
  width: 1em;
  height: 1em;
  fill: #FFF;
}
.icon-next {
  width: 0.62em;
  height: 1em;
  fill: #5C2D91;
}
.icon-telegram {
  width: 1.13em;
  height: 1em;
  fill: #FFF;
}
.icon-twitter {
  width: 1.2em;
  height: 1em;
  fill: #FFF;
}
.icon-youtube {
  width: 1.36em;
  height: 1em;
  fill: #FFF;
}
